.PlayerControl {
  position: absolute;
  bottom: 10px;
  left: 10px;
  right: 10px;
  z-index: 400;
  max-width: 900px;
  background-color: rgba(255, 255, 255, 0.65);
  border: solid 1px rgba(155, 155, 155, 0.5);
  border-radius: 4px;
}

.PlayerControl__loader {
  position: absolute;
  bottom: 10px;
  left: 10px;
  z-index: 500;
}

.PlayerControl__control {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3px 5px;
}

.PlayerControl__footer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 3px 5px;
  font-size: 13.3px;
  user-select: none;
}
